//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TopicCard',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: Object,
      default: () => {},
    },
  },
};
