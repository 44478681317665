//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import AvatarCard from "../components/AvatarCard.vue";
import BaseButton from "../components/BaseButton.vue";
import EventCard from "../components/EventCard.vue";
import PinCard from "../components/PinCard.vue";
import TopicCard from "../components/TopicsCard.vue";

export default {
  name: "Profile",
  components: {
    AvatarCard,
    BaseButton,
    EventCard,
    PinCard,
    TopicCard
  },
  data() {
    return {
      defaultImage: require("../assets/default.png"),
      isMobileMenuOpen: false,
      mobile: true,
      width: 210
    };
  },
  computed: {
    ...mapGetters({
      user: "users/getCurrentUser",
    }),
    getCategoryColor() {
      return this.$store.getters["categories/getCategoryById"](
        this.user.categoryId
      ).color;
    },
  },
  beforeUpdate() {
    if (this.$q.screen.width >= 1024) this.mobile = false;
    if (this.$q.screen.width >= 1800) this.width = 220;
  },
};
