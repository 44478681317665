//
//
//
//
//
//
//


export default {
  name: "AvatarCard",
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
};
