//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PinCard",
};
